import React from 'react';
import './privacy.css'

const PrivacyPolicy = () => {
    return (
        <div className='privacy_policy w-full h-full '>
            <div className='privacy_policy_child'>
                <h1>Privacy Policy</h1>
                <p>EklavyaSolution and its subsidiaries ("EklavyaSolution" or "we") are sensitive to privacy issues on the Internet and in general has no objection to the User accessing its website without providing any personal information. However, there are circumstances like those listed below, when EklavyaSolution may request you to provide your personal information. We are committed to protecting personal information that you may share with us. This Privacy Policy describes how we may collect and use your personal information.</p>
                <h3>Information We Collect</h3>
                <p>Personal information means any information that may be used to identify an individual, including, but not limited to, a first and last name, email address, Skype id, a home, postal or other physical address, other contact information, title, occupation, industry, personal interests, and other information when needed to provide a service or product or carry out a transaction you have requested. Unless you choose to voluntarily provide the information to us, we do not collect personally identifiable information about you, such as your name, address, telephone number, fax number, or e-mail address. For instance, you may be asked to provide us with your personal information when you apply for a job opening, register to attend a company event, or wish to subscribe to a newsletter. If you elect to submit any personal information, your submission is subject to the terms of this Privacy Policy and you consent to such terms.</p>
                <p>Like many other commercial websites, the EklavyaSolution website may use standard technologies, such as "cookies" and other HTML tools, to collect information about how you use the site or to record that you opened an email marketing message. As such, we may log your IP address (the Internet address of your computer) to give us an idea of which part of our website you visit and how long you spend there. See section entitled "Cookies" below..</p>
                <h3>Cookies</h3>
                <p>Some parts of the EklavyaSolution website use cookies to track user traffic patterns. We do this in order to determine the usefulness of our website information to our users and to see how effective our navigational structure is in helping users reach that information. A cookie is a small data file that certain web sites write to your hard drive when you visit them. A cookie file can contain information such as a user ID that the site uses to track the pages you have visited.</p>
                <p>If you prefer not to receive cookies while browsing our website, you can set your browser to warn you before accepting cookies and refuse the cookie when your browser alerts you to its presence. You can also refuse all cookies by turning them off in your browser, although you may not be able to take full advantage of the EklavyaSolution website if you do so. In particular, you may be required to accept cookies in order to complete certain actions on our website. You do not need to have cookies turned on, however, to use/navigate through many parts of our website, except access to certain of EklavyaSolution's web pages that require a login and password.</p>
                <h3>Notice</h3>
                <p>When personal information is collected, we will inform you at the point of collection the purpose for the collection. EklavyaSolution will not transfer your personal information to third parties without your consent, except under the limited conditions described under the section entitled "Information Sharing and Disclosure" below.</p>
                <p>In most cases we will provide you with the opportunity to "opt in" prior to receiving direct marketing or market research information. This means we will require your affirmative action to indicate your consent before we use your information for purposes other than the purpose for which it was submitted. At a minimum, we will always give you the opportunity to "opt out" of receiving such materials. This means we assume you have given us your consent to collect and use your information in accordance with this Privacy Policy unless you take affirmative action to indicate that you do not consent, for instance by clicking or checking the appropriate option or box at the point of collection.</p>
                <h3>How We Use Information Collected</h3>
                <p>EklavyaSolution uses personal information for several general purposes: to fulfill your requests for certain products and services, to personalize your experience on our website, to keep you up to date on the latest product announcements, software updates, special offers or other information we think you would like to hear about either from us or from our business partners, and to better understand your needs and provide you with better services. We may also use your information to send you, or to have our business partners send you, direct marketing information or contact you for market research.</p>
                <h3>Information Sharing and Disclosure</h3>
                <h5>We do not transfer or share your information except as provided below:</h5>
                <ul>
                    <li>Your personal information may be transferred or shared with other EklavyaSolution offices or subsidiaries around the world. This may result in your information crossing from your country or jurisdiction to other countries or jurisdictions around the world. All such entities are governed by this Privacy Policy.</li>
                    <li>EklavyaSolution may also share your information to third parties if we collect your information for a specific purpose (e.g., to register you for an event or register for the newsletter) and such third party must receive your information for registration purposes (e.g., third party operates the newsletter).</li>
                    <li>EklavyaSolution may also share your information with our business partners in those cases where we believe that your business might be served and we can better provide you with updates on services and benefits provided by us and our business partners.</li>
                    <li>EklavyaSolution may also send your personal information to other companies or people if:<br /> we have your consent to share the information;<br />or if required to do so by law, or if we have a good-faith belief that such action is necessary to comply with a court order or subpoena, to cooperate with investigations by law enforcement or regulatory authorities or to participate or cooperate with a judicial proceeding, or in urgent circumstances, to protect personal safety, the public or our websites; </li>
                    <li>In the event of a merger, consolidation or reorganization involving EklavyaSolution, acquisition of EklavyaSolution by another company, a sale of all or a portion of EklavyaSolution's assets, or other similar transaction, your personal information will, in most instances, be transferred to the control of the third party that is part of that transaction.</li>
                </ul>
                <h3>Links</h3>
                <p>The EklavyaSolution website contains links to other websites. Please be aware that EklavyaSolution is not responsible for the privacy practices of these sites. This privacy statement applies solely to information collected by this website. We encourage our Users to read the privacy statements of each website they visit when leaving the EklavyaSolution website.</p>
                <h3>Data Security</h3>
                <p>EklavyaSolution and its companies are committed to protecting your personal information and do so with certain physical and technological procedures. However, we cannot ensure the security of the information as you transmit it to us, and so we urge you to take every precaution to protect your personal data when you are on the Internet. This includes using a secure browser where applicable.</p>
                <h3>Changes to this Privacy Policy</h3>
                <p>We may amend this Privacy Policy from time to time. If we make any substantial changes in the way we use your personal information we will make that information available by posting a notice on this site. We may also, but are not required to, notify you by email or regular mail.<br />This Privacy Policy was last updated on Jan 7, 2023</p>
            </div>
        </div>
    )
}

export default PrivacyPolicy